.container {
  width: 100%;
}

.button {
  width: 100%;
}
.fileWrapper {
  margin-bottom: 20px;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.disabled input {
  background: #f7f7f7;
  font-weight: bold;
  color: #000;
  cursor: not-allowed;
}

.switch > *:first-child {
  font-weight: bold;
}
.switchLabel {
  margin-bottom: 10px;
}

.email > *:first-child {
  text-transform: lowercase !important;
}

.buttonWrapper {
  display: none;
}

.announcement {
  margin-bottom: 0 !important;
}

.buttons,.hideUntilButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 12px 0;
}


.buttons > *:first-child,.hideUntilButtons > *:first-child {
  margin-right: 10px;
}

@media (max-width: 1024px){
  .button {
    position: fixed !important;
    right: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    height: 55px !important;
    border-radius: 0 !important;
    z-index: 9999;
  }
  .container {
    padding-bottom: 60px;
  }
}
@media (min-width: 1024px){
  .container {
    display: flex;
  }
  .shrink {
    width: 400px;
  }
  .container > * {
    flex-grow: 1;
    flex-basis: 50%;
  }
  .container > *:not(:last-child) {
    margin-right: 45px;
  }
  .fileWrapper {
    margin-bottom: 0;
  }
  .row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 800px;
  }

  .row > * {
    min-width: 50px;
  }

  .row > *:first-child {
    width: 52% !important;
  }

  .row > *:nth-child(2) {
    width: 20% !important;
  }

  .row > *:last-child {
    width: 24% !important;
  }

  .row > *:not(:first-child) > *:first-child {
    min-width: 25px !important;
  }
  .button,.lowerButton {
    width: auto;
  }
  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    margin: 40px 0 40px 0;
    width: 100%;
  }
  .bottomButton {
    width: auto;
  }
}
